<template>
  <b-card-code no-body title="User List">
      <div class="dt_adv_search ml-1 mr-1">
        <div class="row">

          <div class="col-md-12">
              <b-button @click="cleanUpForm()" v-if="checkPermission('create user')" v-b-modal.form-user-modal class="btn btn-info btn-sm mb-1" data-toggle="tooltip" data-placement="top" title="Add User">
                <feather-icon
                    icon="PlusCircleIcon"
                />
                Add User
              </b-button>

              <b-modal no-close-on-backdrop id="form-user-modal"  size="lg" :title="editId != null ? 'Edit User' : 'Add User'">
                <div class="form">

                  <h5>Basic Information</h5>

                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="">Name:</label>
                        <input type="text" class="form-control" v-model="formPayload.name" placeholder="User/member name...">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="">Email:</label>
                        <input type="email" class="form-control" v-model="formPayload.email" placeholder="User email...">
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="type">Type</label>
                        <select id="type" class="form-control" v-model="formPayload.type">
                          <option value="supplier">Supplier</option>
                          <option value="trader">Trader</option>
                          <option value="user">User</option>
<!--                          <option value="warrior_seller">Warrior Seller</option>-->
<!--                          <option value="warrior_trader">Warrior Trader</option>-->
<!--                          <option value="guardian">Guardian</option>-->
                        </select>
                        <!-- <select id="type" class="form-control" v-model="formPayload.type" v-else>
                          <option value="">Buyer &amp; Seller</option>
                          <option value="buyer">Buyer</option>
                          <option value="seller">Seller</option>
                        </select> -->
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="">Trader Code:</label>
                    <input type="text" class="form-control" v-model="formPayload.code" placeholder="User code...">
                  </div>

                  <div class="form-group">
                    <label for="">Phone Number:</label>
                    <input type="text" class="form-control" v-model="formPayload.phone" placeholder="User phone...">
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="">Photo: (Optional)</label><br>
                        <input type="file" id="file" ref="file" @change="changePhoto()">
                      </div>
                    </div>
                    <div class="col-6">
                      <div v-if="formPayload.photo_url != null">
                        <small>Current photo:</small><br>
                        <img v-bind:src="formPayload.photo_url" style="max-width: 100%;">
                      </div>
                    </div>
                  </div>

                  <br>
                  <h5>Account &amp; Password</h5>

                  <div class="form-group">
                    <label for="">EPACT ID:</label>
                    <input type="text" class="form-control" v-model="formPayload.epact_vendor_id" placeholder="User epact vendor id...">
                    <small>This field used to syncronize balance between app and EPACT.</small>
                  </div>

                  <div class="form-group">
                    <label for="">Password:</label>
                    <input type="password" class="form-control" v-model="formPayload.password" placeholder="User password...">
                  </div>

                  <div class="form-group">
                    <label for="">Password Confirmation:</label>
                    <input type="password" class="form-control" v-model="formPayload.password_confirmation" placeholder="User password...">
                  </div>

                  <div class="form-group" v-if="editId == null">
                    <label for="is_active">User Status:</label>
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input class="custom-control-input" type="checkbox" v-model="formPayload.is_active" :id="'user_status'">
                        <label class="custom-control-label" :for="'user_status'">
                          Active
                        </label>
                      </div>
                    </div>
                  </div>

                  <br>
                  <h5>Address</h5>

                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label for="country">Country</label>
                        <select id="country" class="form-control" v-model="formPayload.country" @change="loadProvince">
                          <option value="">-- Select Country --</option>
                          <option v-for="country in countries" :key="country.code" :data-countrycode="country.code">{{ country.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label for="province">Province:</label>
                        <input v-if="provinces.length == 0" type="text" class="form-control" id="province" v-model="formPayload.province" placeholder="User province...">
                        <select v-else id="province" class="form-control" v-model="formPayload.province" @change="loadCity">
                          <option value="">-- Select Province --</option>
                          <option v-for="province in provinces" :key="province.id" :data-provinceid="province.id">{{ province.name }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label for="city">City:</label>
                        <input v-if="cities.length == 0 || provinces.length == 0" type="text" class="form-control" id="city" v-model="formPayload.city" placeholder="User city...">
                        <select v-else id="city" class="form-control" v-model="formPayload.city">
                          <option value="">-- Select City --</option>
                          <option v-for="city in cities" :key="city.id" :data-id="city.id">{{ city.name }}</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="">Full Address:</label>
                    <textarea class="form-control" v-model="formPayload.address" placeholder="User address..."></textarea>
                  </div>

                  <br>
                  <h5>PIC Information</h5>

                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label for="pic_name">PIC Name:</label>
                        <input type="text" class="form-control" id="pic_name" v-model="formPayload.pic_name" placeholder="PIC Name...">
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label for="pic_position">PIC Position:</label>
                        <input type="text" class="form-control" id="pic_position" v-model="formPayload.pic_position" placeholder="PIC Position...">
                      </div>
                    </div>
                  </div>

                  <br>
                  <h5>Additional Information</h5>

                  <div class="row">

                    <div v-for="(label, key) in additionalFieldString" class="col-6" :key="'input-' + key">
                      <div class="form-group">
                        <label :for="key">{{ label }}:</label>
                        <input type="text" class="form-control" :id="key" v-model="formPayload[key]" :placeholder="label">
                      </div>
                    </div>

                    <div v-for="(label, key) in additionalFieldFile" class="col-6" :key="'value-' + key">
                      <div class="form-group">
                        <label :for="key">{{ label }}</label><br>
                        <input type="file" :id="key" :ref="key" @change="changeFile($event.target.files, key)">
                        <div v-if="formPayload[key + '_url'] != null">
                          <small>Current {{ label }}:</small><br>
                          <a :href="formPayload[key + '_url']" target="_blank">Open File</a>
                        </div>
                      </div>
                    </div>

                  </div>

                  <!-- Restricted Commodity -->
                  <br>
                  <h5>Access to Restricted Asset</h5>

                  <button class="btn btn-sm btn-success" @click="addGrantedCommodity()">
                    <feather-icon
                        icon="PlusIcon"
                    /> Add Item
                  </button><br>
                  <small>Fill it if you want give access to restricted Asset:</small>
                  <table class="table table-hover">
                    <tr v-for="granted, index in formPayload.grant_restricted_commodity" :key="index">
                      <td class="pl-0" width="45%">
                        <select class="form-control" v-model="formPayload.grant_restricted_commodity[index].commodity_slug">
                          <option value="">-- Select Asset --</option>
                          <template v-for="(typeLabel, typeKey) in commodityTypes">
                            <optgroup :label="typeLabel" :key="typeKey">
                              <template v-for="commodity in restrictedCommodities.filter(item => item.type == typeKey)">
                                <option :key="commodity.slug" :value="commodity.slug" v-if="!formPayload.grant_restricted_commodity.map(function(item){ return item.commodity_slug; }).includes(commodity.slug) || commodity.slug == formPayload.grant_restricted_commodity[index].commodity_slug">
                                  {{ commodity.name }}
                                </option>
                              </template>
                            </optgroup>
                          </template>
                        </select>
                      </td>
                      <td width="15%" style="padding-top: 20px;" class="pl-0">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox">
                            <input class="custom-control-input form-control" type="checkbox" v-model="formPayload.grant_restricted_commodity[index].can_see" :id="'checkbox_commodity_see_' + index">
                            <label class="custom-control-label" :for="'checkbox_commodity_see_' + index">
                              Can See
                            </label>
                          </div>
                        </div>
                      </td>
                      <td width="15%" style="padding-top: 20px;" class="pl-0">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox">
                            <input class="custom-control-input form-control" type="checkbox" v-model="formPayload.grant_restricted_commodity[index].can_buy" :id="'checkbox_commodity_buy_' + index">
                            <label class="custom-control-label" :for="'checkbox_commodity_buy_' + index">
                              Can Buy
                            </label>
                          </div>
                        </div>
                      </td>
                      <td width="15%" style="padding-top: 20px;" class="pl-0">
                        <div class="form-group">
                          <div class="custom-control custom-checkbox">
                            <input class="custom-control-input form-control" type="checkbox" v-model="formPayload.grant_restricted_commodity[index].can_sell" :id="'checkbox_commodity_sell_' + index">
                            <label class="custom-control-label" :for="'checkbox_commodity_sell_' + index">
                              Can Sell
                            </label>
                          </div>
                        </div>
                      </td>
                      <td class="text-right pr-0">
                        <button class="btn btn-sm btn-danger ml-1" @click="removeGrantedCommodity(index)">
                          <feather-icon
                              icon="XIcon"
                          />
                        </button>
                      </td>
                    </tr>
                  </table>

                  <!-- Roles -->
                  <!-- <br>
                  <h5>Roles</h5>

                  <div class="row">

                    <div class="col-3" v-for="role in roles" :key="role.id">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input class="custom-control-input" type="checkbox" v-model="formPayload.roles" :value="role.id" :id="'role_checkbox_' + role.id">
                          <label class="custom-control-label text-capitalize" :for="'role_checkbox_' + role.id">
                            {{ role.name }}
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>

                  <small>If you check one of the roles above, the user will be able to enter the admin page according to the access that the role has.</small> -->

                </div>

                <template #modal-footer="{}">
                  <div v-if="isLoading">
                    <br>
                    <b-spinner
                      class="mb-2"
                      variant="primary"
                    /><br>
                  </div>
                  <b-button v-if="editId == null" variant="success" @click="createItem()" v-bind:disabled="isLoading">
                    Save User
                  </b-button>
                  <b-button v-else variant="success" @click="updateItem()" v-bind:disabled="isLoading">
                    Save User
                  </b-button>
                </template>
              </b-modal>
          </div>

            <div class="col-12">
                <div class="form-row mb-1">
                    <div class="col-lg-4">
                        <select class="form-control form-control-sm" v-model="filter.approval_type">
                          <option value="">-- All Status --</option>
                          <option value="pending">Pending</option>
                          <option value="active">Active/Approved</option>
                          <option value="suspend">Suspended</option>
                        </select>
                    </div>
                    <div class="col-lg-4">
                        <!-- <select class="form-control form-control-sm" v-model="filter.user_type">
                          <option value="">-- All User --</option>
                          <option value="admin">Admin</option>
                          <option value="normal">Trader</option>
                        </select> -->
                    </div>
                    <div class="col-lg-4">
                        <input type="text" v-model="filter.keyword" placeholder="Search by name, email or address" class="form-control form-control-sm float-right" style="width: 200px;">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>#</th>
            <th>User</th>
            <th>Type</th>
            <th>Phone</th>
            <th>Trader Code</th>
            <!-- <th>Phone</th> -->
            <th>Status</th>
            <th>Registration Time</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="6" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr v-else v-for="item in result.data" v-bind:key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}<br><small>{{ item.email }}</small></td>
            <td class="text-capitalize">
              {{ item.type != null ? item.type.replace("_", " ") : '' }}
            </td>
            <td>{{ item.phone == null ? '-' : item.phone }}</td>
            <td>{{ item.code }}</td>
            <!-- <td>{{ item.phone == null ? '-' : item.phone }}</td> -->
            <td>
                <span class="badge text-capitalize badge-pill" v-bind:class="item.approval_status == 'active' ? 'badge-light-success' : 'badge-light-secondary'">{{ item.approval_status }}</span>
            </td>
            <td>{{ item.register_at == null ? '-' : item.register_at }}</td>
            <td>
              <b-dropdown
                    variant="info"
                    size="sm"
                >
                    <template #button-content>
                      Action
                    </template>
                    <b-dropdown-item v-if="checkPermission('approve user') && item.approval_status == 'pending'" @click="approveUser(item.id)">
                      <feather-icon
                          icon="CheckIcon"
                          class="mr-75"
                      />
                      Approve User
                    </b-dropdown-item>
                    <b-dropdown-item v-if="checkPermission('reject user') && item.approval_status == 'pending'" @click="rejectUser(item.id)">
                      <feather-icon
                          icon="XIcon"
                          class="mr-75"
                      />
                      Reject User
                    </b-dropdown-item>
                    <b-dropdown-item v-if="item.approval_status == 'active' && checkPermission('send reset password user')" @click="resetPassword(item.id)">
                      <feather-icon
                          icon="KeyIcon"
                          class="mr-75"
                      />
                      Reset Password
                    </b-dropdown-item>
                    <b-dropdown-item v-if="checkPermission('show users')" @click="goToDetail(item.id)">
                      <feather-icon
                          icon="EyeIcon"
                          class="mr-75"
                      />
                      Detail User
                    </b-dropdown-item>
                    <b-dropdown-item v-if="checkPermission('update user')" @click="editItem(item)">
                      <feather-icon
                          icon="EditIcon"
                          class="mr-75"
                      />
                      Edit User
                    </b-dropdown-item>
                    <b-dropdown-item v-if="checkPermission('delete user')" @click="deleteItem(item.id)">
                      <feather-icon
                          icon="TrashIcon"
                          class="mr-75"
                      />
                      Delete User
                    </b-dropdown-item>
                </b-dropdown>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="7" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue';
import VuejsDialog from 'vuejs-dialog';
Vue.use(VuejsDialog);
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

export default {
  title () {
    return `User List`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    BSpinner
  },
  watch: {
    filter: {
      handler: _.debounce(function() {
        this.getData();
      }, 300),
      deep: true
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        keyword: '',
        user_type: '',
        approval_type: this.$route.query.status == null ? '' : this.$route.query.status,
      },
      isLoading: false,
      formPayload: Object,
      editId: null,
      // roles: [],
      restrictedCommodities: [],
      countries: [],
      provinces: [],
      cities: [],
      isTerra: process.env.IS_TERRA == 'true',
      commodityTypes: {
        premium: "Premium Offset Market",
        standard: "Standard Offset Market",
      },
      additionalFieldString: {
        company_sector: 'Company Sector',
        sectoral_scope: 'Sectoral Scope',
        project_type: 'Project Type',
        project_name: 'Project Name',
        project_url: 'Project URL',
        project_country: 'Project Country',
      },
      additionalFieldFile: {
        company_profile: 'Company Profile',
        project_design_document_standard: 'Project Design Document Standard',
        validation_and_verification_document_standard: 'Validation and Verification Document Standard',
        issuance_document: 'Issuance Document',
        deed_of_incorporation_tax: 'Deed of Incorpration + Tax',
        deed_of_incorporation: 'Deed of Incorporation',
        identification: 'Identification',
        tax_identification: 'Tax Identification',
      },
    }
  },
  created() {
    this.getData();
    this.loadFormData();
  },
  methods: {
    cleanUpForm() {
      this.editId = null
      this.formPayload = {
        name: '',
        email: '',
        code: '',
        epact_vendor_id: '',
        password: '',
        password_confirmation: '',
        phone: '',
        address: '',
        photo: '',
        country: '',
        province: '',
        city: '',
        pic_name: '',
        pic_position: '',
        is_active: true,
        type: '',
        grant_restricted_commodity: [],
        company_sector: '',
        sectoral_scope: '',
        project_type: '',
        project_name: '',
        project_url: '',
        project_country: '',
        company_profile: null,
        project_design_document_standard: null,
        validation_and_verification_document_standard: null,
        issuance_document: null,
        deed_of_incorporation_tax: null,
        deed_of_incorporation: null,
        identification: null,
        tax_identification: null,
      };

      // Prepare restricted commodity
      this.addGrantedCommodity()
      this.provinces = []
      this.cities = []
    },
    loadFormData() {

      // Load Restricted Commodity
      this.$http.get('/public/commodities')
      .then(response => {
        this.restrictedCommodities = response.data.data
      })

      // Load Countries
      this.$http.get('/public/country')
      .then(response => {
        this.countries = response.data.data
      })

    },
    loadProvince() {
      const select = document.getElementById('country');
      if (typeof select == 'object' && select.selectedIndex != 0) {
        const data = select.options[select.selectedIndex].dataset;
        var countrycode = data.countrycode

        // Load Provinces
        this.$http.get('/public/provinces?country_code=' + countrycode)
        .then(response => {
          this.provinces = response.data.data
        })
      }
    },
    loadCity() {
      const select = document.getElementById('province');
      if (typeof select == 'object' && select.selectedIndex != 0) {
        const data = select.options[select.selectedIndex].dataset;
        var provinceid = data.provinceid

        // Load Provinces
        this.$http.get('/public/cities?province_id=' + provinceid)
        .then(response => {
          this.cities = response.data.data
        })
      }
    },
    getGrantedRestrictedCommodityPayload() {
      return {
        commodity_slug: '',
        can_see: true,
        can_buy: false,
        can_sell: false
      };
    },
    addGrantedCommodity() {
      this.formPayload.grant_restricted_commodity.push(
        this.getGrantedRestrictedCommodityPayload()
      );
    },
    removeGrantedCommodity(index) {
      this.formPayload.grant_restricted_commodity.splice(index, 1);
    },
    changePhoto() {
      this.formPayload.photo = this.$refs.file.files[0];
    },
    changeFile(fileList, key) {
      if (!fileList.length) return;
      this.formPayload[key] = fileList[0];
    },
    createItem() {
      var form = this.preparePayload();
      this.isLoading = true

      this.$http.post('/admin/users', form, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {

        this.$bvModal.hide('form-user-modal')
        this.getData(this.currentPage)
        successNotification(this, 'Success', 'User successfully created')
        this.cleanUpForm();
        this.isLoading = false

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
        this.isLoading = false
      })
    },
    updateItem() {
      var form = this.preparePayload();
      this.isLoading = true
      form.append('_method', 'PATCH');

      this.$http.post('/admin/users/' + this.editId, form, {
        headers: { 'content-type': 'multipart/form-data' }
      })
      .then(response => {

        this.$bvModal.hide('form-user-modal')
        this.getData(this.currentPage)
        successNotification(this, 'Success', 'User successfully updated!')
        this.cleanUpForm();
        this.isLoading = false

      })
      .catch(error => {
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Oops!', error.response.data.meta.messages)
        }
        this.isLoading = false
      })
    },
    preparePayload() {
      this.formPayload.is_active = this.formPayload.is_active ? 1 : 0;

      var form = new FormData();
      for (var key in this.formPayload) {
          if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
            form.append(key, this.formPayload[key]);
          }
      }

      this.formPayload.grant_restricted_commodity.forEach(function(item, index){
        if (item.can_buy || item.can_sell) {
          item.can_see = true;
        }
      });

      // Prepare granted commdity payload
      var context = this;
      this.formPayload.grant_restricted_commodity.forEach(function(item, index){
        if (item.commodity_slug == '' || item.commodity_slug == null) {
          context.removeGrantedCommodity(index);
        } else {
          form.append('grant_restricted_commodity[' + index + '][commodity_slug]', item.commodity_slug);
          form.append('grant_restricted_commodity[' + index + '][can_see]', item.can_see ? 1 : 0);
          form.append('grant_restricted_commodity[' + index + '][can_buy]', item.can_buy ? 1 : 0);
          form.append('grant_restricted_commodity[' + index + '][can_sell]', item.can_sell ? 1 : 0);
        }
      });

      // Prepare role payload
      // this.formPayload.roles.forEach(function(item){
      //   form.append('roles[]', item);
      // });

      return form;
    },
    editItem(item) {
      this.cleanUpForm();

      this.editId = item.id

      // Get granted restricted commodity from API Detail
      var granted = []
      this.$http.get('/admin/users/' + this.editId).then(response => {

        var granteds = response.data.data.access_to_restricted_commodity;
        if (granteds.length > 0) {
          granteds.forEach(function(item){
            granted.push({
              commodity_slug: item.commodity.slug,
              can_see: item.can_see,
              can_buy: item.can_buy,
              can_sell: item.can_sell,
            });
          })
        }

        this.loadProvince()
        var context = this
        setTimeout(function(){
          context.loadCity()
        }, 1000);
      })

      this.formPayload = {
        name: item.name,
        email: item.email,
        code: item.code,
        epact_vendor_id: item.epact_vendor_id,
        password: item.password,
        password_confirmation: item.password_confirmation,
        phone: item.phone,
        address: item.address,
        photo_url: item.photo_url,
        country: item.country,
        province: item.province,
        city: item.city,
        pic_name: item.pic_name,
        pic_position: item.pic_position,
        type: item.type == null ? '' : item.type,
        grant_restricted_commodity: granted,
        company_sector: item.company_sector,
        sectoral_scope: item.sectoral_scope,
        project_type: item.project_type,
        project_name: item.project_name,
        project_url: item.project_url,
        project_country: item.project_country,
        company_profile_url: item.company_profile,
        project_design_document_standard_url: item.project_design_document_standard,
        validation_and_verification_document_standard_url: item.validation_and_verification_document_standard,
        issuance_document_url: item.issuance_document,
        deed_of_incorporation_tax_url: item.deed_of_incorporation_tax,
        deed_of_incorporation_url: item.deed_of_incorporation,
        identification_url: item.identification,
        tax_identification_url: item.tax_identification,
      }

      this.$bvModal.show('form-user-modal')
    },
    getData(page = 1) {

      this.isLoading = true;
      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page
      queryParams.user_type = 'normal'

      this.$http.get('/admin/users', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        this.isLoading = false;
      })

    },
    deleteItem(id) {

      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this user?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.$http.delete('admin/users/' + id)
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'User successfully deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })

        }
      })

    },
    resetPassword(id) {

      this.$swal({
        title: 'Are you sure?',
        text: 'System will generate password and send it to user email.',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.$http.post('admin/users/' + id + '/reset-password')
          .then(response => {

            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Password has been updated and sent to user email!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })

        }
      })

    },
    approveUser(id) {

      this.$swal({
        title: 'Are you sure?',
        text: 'System will send trader code & password to user.',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.$http.post('admin/users/' + id + '/approve')
          .then(response => {

            this.getData(this.currentPage)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'User successfully approved!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })

        }
      })

    },
    goToDetail(id) {
      this.$router.push({ name: 'users.show', params: { id: id } })
    },
    rejectUser(id) {

      this.$dialog
      .prompt({
        title: "Reject User",
      }, {
        promptHelp: 'Enter reject reason here, system will send rejection email to user with this reason:'
      })
      .then(dialog => {

        this.$http.post('admin/users/' + id + '/reject', {
          reject_reason: dialog.data
        })
        .then(response => {

          this.getData(this.currentPage)
          successNotification(this, 'Success', 'User successfully rejected!')

        })
        .catch(error => {
          if (error.response.data.meta.messages.length > 0) {
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
        })

      })

    },
  }
}
</script>
